<template>
  <div class="page-common page-kvconfig-list">
    <div class="page-params">
      <el-input
        clearable
        class="param-item"
        placeholder="输入Id"
        v-model="params.id"
        @keyup.enter="resetTable"
        @clear="resetTable"
      />
      <el-input
        clearable
        class="param-item"
        placeholder="输入Key"
        v-model="params.key"
        @keyup.enter="resetTable"
        @clear="resetTable"
      />
      <el-input
        clearable
        class="param-item"
        placeholder="输入Name"
        v-model="params.name"
        @keyup.enter="resetTable"
        @clear="resetTable"
      />
      <el-select
        clearable
        class="param-item"
        placeholder="请选择所属平台"
        v-model="params.suitable_for"
        @change="resetTable"
      >
        <el-option
          :key="item"
          :label="item"
          :value="item"
          v-for="item in ['iOS', 'Android', 'PC', '641', 'MP']"
        />
      </el-select>
      <el-button class="param-item" type="primary" icon="el-icon-search" @click="resetTable">搜索</el-button>
      <el-button
        style="margin-left: auto"
        class="param-item"
        type="primary"
        icon="el-icon-plus"
        @click="$router.push({name: 'KVCreate'})"
      >创建</el-button>
    </div>
    <CommonTable
      ref="table"
      sticky-header
      :params="params"
      :mapData="mapData"
      :mapTotal="mapTotal"
      :promise="getKVConfigList"
    >
      <el-table-column prop="id" label="ID" width="100" />
      <el-table-column prop="key" label="Key" />
      <el-table-column prop="name" label="Name" />
      <el-table-column prop="suitableFor" label="所属平台" align="center" />
      <el-table-column prop="belongsTo" label="所属产品" align="center" />
      <el-table-column prop="description" label="描述" align="center" />
      <el-table-column align="center" label="是否私有" width="120">
        <template #default="{row}">
          <span>{{ row.isPrivate }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="150">
        <template #default="{row}">
          <el-button size="mini" @click="handleEdit(row.id)">编辑</el-button>
          <el-popconfirm title="确认删除操作？" @confirm="handleDelete(row.id)">
            <template #reference>
              <el-button style="margin-left: 10px" size="mini" type="danger">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </CommonTable>
  </div>
</template>

<script>
import CommonTable from '@/components/base/common-table'
import { getKVConfigList, deleteKVConfig } from '@/domains/base-baoer'

export default {
  name: 'KVList',
  components: {
    CommonTable
  },
  data() {
    this.getKVConfigList = getKVConfigList
    this.mapData = (res) => res.items
    this.mapTotal = (res) => res.item_count
    return {
      params: {
        id: '',
        name: '',
        key: '',
        suitable_for: ''
      }
    }
  },
  methods: {
    resetTable() {
      this.$refs.table.reset()
    },
    handleEdit(id) {
      this.$router.push({ name: 'KVEdit', params: { id } })
    },
    handleDelete(id) {
      deleteKVConfig(id).then(() => {
        this.$message.success('删除成功')
        this.$refs.table.getData()
      })
    }
  }
}
</script>
